<template>
  <g>
    <svg:style>
      .cls-1, .cls-10, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }

      .cls-8 {
        fill: url(#inox-gradient-4);
      }

      .cls-9 {
        fill: url(#inox-gradient-5);
      }

      .cls-10 {
        fill: url(#inox-gradient-6);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 9.95"
      :y1="doorTopHeight1 + 147.88"
      :x2="doorLeftWidth1 + 15.54"
      :y2="doorTopHeight1 + 147.88"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14090.18"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14090.18"
      gradientTransform="translate(-1066.91 -13736.6)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14124.55"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14124.55"
      gradientTransform="translate(-1066.91 -13805.33)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14160.63"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14160.63"
      gradientTransform="translate(-1066.91 -13877.49)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14196.7"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14196.7"
      gradientTransform="translate(-1066.91 -13949.65)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14232.78"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14232.78"
      gradientTransform="translate(-1066.91 -14021.8)"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-6"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 1573.02"
      :y1="doorTopHeight1 + 14266.95"
      :x2="doorLeftWidth1 + 1517.94"
      :y2="doorTopHeight1 + 14266.95"
      gradientTransform="translate(-1066.91 -14090.14)"
      xlink:href="#handle-gradient"/>
    <g id="V12">
      <rect
        id="glass"
        class="cls-4"
        :x="doorLeftWidth + 38.11"
        :y="doorTopHeight + 59.18"
        width="24.72"
        height="178.74"/>
      <g id="inoxes"
        v-if="showInox">
        <rect
          id="inox"
          class="cls-5"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 59"
          :width="inoxW"
          height="1.97"/>
        <rect
          id="inox-2"
          data-name="inox"
          class="cls-6"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 59 + 35"
          :width="inoxW"
          height="1.97"/>
        <rect
          id="inox-3"
          data-name="inox"
          class="cls-7"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 59 + 35 + 35"
          :width="inoxW"
          height="1.97"/>
        <rect
          id="inox-4"
          data-name="inox"
          class="cls-8"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 59 + 35 + 35 + 35"
          :width="inoxW"
          height="1.97"/>
        <rect
          id="inox-5"
          data-name="inox"
          class="cls-9"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 59 + 35 + 35 + 35 + 35"
          :width="inoxW"
          height="1.97"/>
        <rect
          id="inox-6"
          data-name="inox"
          class="cls-10"
          :x="doorLeftWidth + 63"
          :y="doorTopHeight + 236"
          :width="inoxW"
          height="1.97"/>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxW() {
      return this.showBg ?
        this.doorWidth - this.inoxOffset * 2 - 41 :
        this.doorWidth - this.leafOffset * 2 - 56
    },
  }
}
</script>
